<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('employeesDeductions.form.CreateADiscount')"
          :description="$t('employeesDeductions.form.FromHereYouCanCreateANewDiscount')"
          v-if="$route.name == 'employeesDeductionsCreate'"
        />
        <addHeader
          :title="$t('employeesDeductions.form.DiscountAdjustment')"
          :description="$t('employeesDeductions.form.FromHereYouCanModifyTheDiscount')"
          v-if="$route.name == 'employeesDeductionsEdit'"
        />
        <div class="mb-4 row">
          <connectionInput
            :title="$t('employeesDeductions.form.ProcessNumbering')"
            :group="$t('employeesDeductions.form.Groups')"
            v-model="item.invoice_group"
            :disabled="
              $user.admin ||
              $user.role.employees_deductions_edit_item
                ? false
                : true
            "
            :hasErorr="errors.invoice_group"
            :error="$t('employeesDeductions.form.ThisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'employeesDeductionsCreate'"
          />
          <formInput
            :title="$t('employeesDeductions.form.OperationCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'employeesDeductionsEdit'"
            maxlength="255"
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>
          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'"> {{$t('employeesDeductions.form.date')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('employeesDeductions.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('employeesDeductions.form.Now')"
                  v-model="item.date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <employeeNameInput
            :disabled="$route.name == 'employeesDeductionsEdit' ? true : false"
          />
          <monyInput
            :title="$t('employeesDeductions.form.balance')"
            v-model="item.employee.salary"
            disabled="true"
          />

          <monyInput :title="$t('employeesDeductions.form.discountAmount')" v-model="item.cost"/>

          <selectInput
            :title="$t('invoices.form.Situation')"
            v-model="item.status"
            :values="[
              { name: $t('invoices.draft'), value: 0 },
              { name: $t('invoices.approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('employeesDeductions.form.discountNotes')"
            :description="$t('employeesDeductions.form.discountNotesDetails')"
          />

          <formTextarea
            :title="$t('employeesDeductions.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea :title="$t('employeesDeductions.form.Note')" v-model="item.notes"/>
          <!-- Divider -->
          <hr class="mt-5 mb-5 col-12" />
          <!-- Buttons -->
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'employeesDeductionsCreate' && !$parent.stopEdit"
          >
            {{$t('employeesDeductions.form.DiscountAddition')}}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'employeesDeductionsEdit' && !$parent.stopEdit"
          >
            {{$t('employeesDeductions.form.DiscountAdjustment')}}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import employeeNameInput from "../elements/add/form/search/employeeNameInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";


import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/employeesDeductions",
      item: {
        invoice_group: this.$option.employees_deductions_invoice_group_id,
        payment_method_id: 0,
        date: "",
        employee_id: 0,
        cost: 0,
        employee: {
          name: "",
          balance: 0,
          salary: 0,
        },
        status: 1,
      },
      model: false,
      errors: {
        cost: false,
        invoice_group: false,
      },
    };
  },
  mounted() {
    this.$updateDatabase(["invoiceGroups", "employees"]);

    if (this.$route.name == "employeesDeductionsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } else {
       var params = this.$getParams()
      if (params.employee_id) {
        var employee = this.$database.employees.find(
          (el) => el.id == params.employee_id
        )
        this.item.employee_id = params.employee_id;
        this.item.employee = employee;
      }
    }

    if (this.$route.name == "employeesDeductionsCreate") {
      this.item.date = this.$nowDate()
    }

    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          this.item.date = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "employeesDeductionsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date);
        if (this.$route.name == "employeesDeductionsCreate") {
          this.$parent.aletText = this.$t('allerts.expenseSuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          // update employee balance
          var employee = this.$database.employees.find((el) => el.id == this.item.employee_id);
          if (employee) {
            employee.balance = parseFloat(employee.balance) - parseFloat(this.item.cost);
          }

          localStorage.database = JSON.stringify(this.$database);
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "employeesDeductionsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText =
                this.$t('allerts.expenseHasBeenModifiedSuccessfully');
              this.$parent.alertType = "success";
              this.$router.go(-1);
              this.$parent.stopEdit = false;
              return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },

  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    loading,
    employeeNameInput,
    VueCtkDateTimePicker,
    subFileInput,
  },
};
</script>
